.navBar {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	background-color: var(--navColor);
	border-radius: 5px;
	font-size: 18px;
	font-weight: 400;
}

.navBar ul {
	display: flex;
	float: left;
	margin: 15px 0;
	padding: 0 25px;
}

.navBar ul li {
	padding: 0 50px;
	list-style-type: none;
}

.navBar a {
	text-decoration: none;
}

.navBar a:link,
.navBar a:visited {
	color: var(--linkColor1);
	text-shadow: 0 2px 3px black;
}

.navBar a:hover,
.navBar a:focus {
	color: var(--linkColorHover1);
}

.navBar a:active {
	color: var(--linkColorActive1);
}

@media (max-width: 767px) {
	.navBar {
		height: auto;
	}

	.navBar ul {
		flex-direction: column;
		margin: 10px;
		padding: 0;
	}

	.navBar ul li {
		padding: 10px 0;
	}
}
