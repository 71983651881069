.linkHub img {
	width: 30px;
	margin: 5px 15px 5px 5px;
}

.linkHub a {
	display: flex;
	align-items: center;
	text-decoration: dotted underline;
}
