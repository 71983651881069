.contactDescription {
	padding: 0;
}

.contactInfo {
	display: inline-block;
	margin-top: 5px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.35);
	border-radius: 30px;
}

span {
	display: block;
	width: 100%;
}

@media (max-width: 767px) {
	.checkTheseOut {
		flex-direction: column;
		align-items: center;
	}
}
